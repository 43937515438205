<!--========================
Author by: Sreng Veasna
Created on: 14.08.2020
Modified:
Company: F2C V2
Description:
=========================-->
<template>
  <div class="d-flex flex-column align-center">
    <v-img width="60px" src="@/assets/logos/wiresk.png" contain></v-img>
    <v-card flat width="400px">
      <v-card-title class="justify-center"> Log into Wiresk </v-card-title>
      <v-card-text>
        <v-form>
          <v-alert
            v-show="isShowAlertLogin"
            text
            type="error"
            transition="fade-transition"
            >{{ loginalertmessage }}</v-alert
          >

          <InputTextField label="EMAIL ADDRESS" v-model="$v.flogin.email" />
          <SecretTextField label="PASSWORD" v-model="$v.flogin.password" />

          <v-btn
            class="mt-4"
            block
            large
            depressed
            color="primary"
            :disabled="$v.flogin.$invalid"
            @click="submitLogin"
            >LOG IN</v-btn
          >
        </v-form>
      </v-card-text>
      <v-card-text class="d-flex justify-space-between">
        <NavigationButton to="/register">Create Account</NavigationButton>
        <NavigationButton to="/forgotpwd">Forgot Password?</NavigationButton>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "TheLogin",

  data() {
    return {
      mode: "login",
      flogin: {
        email: "",
        password: "",
      },
      isShowAlertLogin: false,
      loginalertmessage: "",
    };
  },
  mixins: [validationMixin],
  validations: {
    flogin: {
      email: { required, email },
      password: { required },
    },
  },
  methods: {
    submitLogin: function (event) {
      if (this.$v.flogin.$invalid) {
        this.$v.flogin.$touch();
        event.preventDefault();
      } else {
        this.$store.commit("toggle", "showHideModalSpinner");
        event.preventDefault();
        this.isShowAlertLogin = false;
        this.loginalertmessage = "";
        this.login(this.flogin["email"], this.flogin["password"]);
      }
    },
    
    login: function (email, pwd) {
      this.$store.commit("set", ["modalSpinnerText", "Loging in..."]);
      var This = this;
      this.$http
        .post("/api/user/login", {
          email: email,
          password: pwd,
        })
        .then(function (response) {
          //console.log("Log login successfully:");
          This.$store.commit("toggle", "showHideModalSpinner");
          if (response.data) {
            var timeRequestToken =
              response.data["requestToken_Expiration"] * 1000;
            var timeRenewToken = response.data["renewToken_Expiration"] * 1000;
            var datetimeRequestToken = new Date(timeRequestToken);
            var datetimeRenewToken = new Date(timeRenewToken);
            This.$cookies.set(
              "requestToken",
              response.data["requestToken"],
              datetimeRequestToken
            );
            This.$cookies.set(
              "renewToken",
              response.data["renewToken"],
              datetimeRenewToken
            );
            This.$router.push("/");
          }
        })
        .catch(function (error) {
          This.$store.commit("toggle", "showHideModalSpinner");
          //console.log("Log login error:");
          console.log(error.response);
          if (error.response) {
            This.isShowAlertLogin = true;
            if (typeof error.response.data.Error === "undefined") {
              This.loginalertmessage = error.response.data.message;
            } else {
              This.loginalertmessage = error.response.data.Error;
            }
          }
        });
    },
    loginErrorMessage(fieldName) {
      if (!this.$v.flogin[fieldName].$dirty) {
        return null;
      }

      if (!this.$v.flogin[fieldName].required) {
        return "Required field";
      }

      if (fieldName == "email" && !this.$v.flogin[fieldName].email) {
        return "Must be a valid email";
      }

      return null;
    },
  },
};
</script>
